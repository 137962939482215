.rs-banners__nav:before {
  background-color: currentColor;
  content: '';
  display: inline-block;
  height: 1em;
  line-height: 1.5;
  width: 1em; }

.owl-prev .rs-banner-nav,
.owl-next svg.rs-banner-nav {
  fill: rgba(125, 134, 152, 0.4); }

.rs-banners-container:hover .owl-prev .rs-banner-nav,
.rs-banners-container:hover .owl-next .rs-banner-nav {
  fill: rgba(125, 134, 152, 0.6); }

.owl-prev svg, .owl-next svg {
  font-size: 24px; }

.rs-banners-container {
  width: 100%;
  position: relative; }

.rs-banners {
  display: block;
  overflow: hidden; }

.rs-banners_banner {
  opacity: 0;
  display: none;
  transition: .3s; }

.rs-banners_banner:first-child {
  opacity: 1;
  display: block;
  position: relative;
  overflow: hidden; }

.rs-banners_background {
  width: 100%;
  height: 100%;
  background-position: center bottom;
  background-size: cover; }

.rs-banners_link {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  z-index: 5;
  top: 0; }

.rs-banners_wrap {
  width: 100%;
  position: absolute;
  top: 0;
  height: 100%;
  left: 0;
  right: 0;
  margin: auto; }

.rs-banners_banner .rs-banners_product {
  position: absolute;
  width: 50%;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  text-align: center;
  height: 100%; }

.rs-banners_banner .rs-banners_product img {
  max-width: 100%;
  width: auto;
  max-height: 100%;
  display: inline-block; }

.rs-banners_infowrap {
  height: 100%;
  width: 50%;
  display: table;
  margin-left: 15px;
  word-break: break-word; }

.rs-banners_infowrap.rs-banners_infovideo {
  width: 100%;
  text-align: center; }

.rs-banners_infowrap .rs-banners_info {
  display: table-cell;
  vertical-align: middle;
  word-break: break-word; }

.rs-banners_infowrap .rs-banners_buttons {
  margin-top: 16px;
  position: relative;
  z-index: 99;
  margin-left: 16px; }

.rs-banners_infowrap .rs-banners_buttons > .btn + .btn {
  margin-left: 8px; }

.rs-banners_infowrap .rs-banners_button {
  margin-left: 15px;
  z-index: 9;
  position: relative; }

.rs-banners_infowrap .rs-banners_info .rs-banners_title,
.rs-banners_infowrap .rs-banners_info .rs-banners_desc,
.rs-banners_infowrap .rs-banners_info .rs-banners_price {
  font-size: 18px;
  display: block;
  padding: 10px 15px;
  word-break: break-word; }

.rs-banners_infowrap .rs-banners_info .rs-banners_title.rs-banners_video-blockwrap,
.rs-banners_infowrap .rs-banners_info .rs-banners_desc.rs-banners_video-blockwrap,
.rs-banners_infowrap .rs-banners_info .rs-banners_price.rs-banners_video-blockwrap {
  padding: 1vw 4vw;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  margin-bottom: 20px; }

.rs-banners_infowrap .rs-banners_info .rs-banners_desc {
  display: none; }

.rs-banners.__product-reverse .rs-banners_banner .rs-banners_product {
  left: 0;
  right: auto; }

.rs-banners.__product-reverse .rs-banners_banner .rs-banners_infowrap {
  right: 0;
  left: auto;
  position: absolute; }

.rs-banners_bottom-line {
  display: none; }

.rs-banners_video {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #000; }

.rs-banners_video video {
  height: 100%;
  width: 100%; }

.rs-banners_video-play {
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: pointer;
  z-index: 9; }

.rs-banners-sidebanners {
  position: relative;
  z-index: 2; }

@media (min-width: 992px) {
  .rs-banners_infowrap .rs-banners_info .rs-banners_desc {
    display: inline-block; } }

/*
* 	Default theme - Owl Carousel CSS File
*/
.rs-banners-container .owl-theme .owl-nav {
  margin-top: 10px;
  text-align: center;
  -webkit-tap-highlight-color: transparent; }

.rs-banners-container .owl-theme .owl-nav [class*='owl-'] {
  color: #FFF;
  font-size: 14px;
  margin: 5px;
  padding: 4px 7px;
  background-color: #ebebeb;
  display: inline-block;
  cursor: pointer;
  border-radius: 3px; }

.rs-banners-container .owl-theme .owl-nav [class*='owl-']:hover {
  background-color: #7aa104;
  color: #FFF;
  text-decoration: none; }

.rs-banners-container .owl-theme .owl-nav .disabled {
  opacity: 0.5;
  cursor: default; }

.rs-banners-container .owl-theme .owl-item {
  -webkit-backface-visibility: visible;
  -moz-backface-visibility: visible;
  backface-visibility: visible;
  overflow: hidden; }

.rs-banners-container .owl-theme .owl-nav {
  position: absolute;
  width: 100%;
  height: 0.1px;
  margin: auto;
  top: 0;
  /* display: none; */
  bottom: 0;
  left: 0;
  right: 0;
  -webkit-transition: all, 0.2s, ease-in-out 0s;
  -moz-transition: all, 0.2s, ease-in-out 0s;
  transition: all, 0.2s, ease-in-out 0s; }

.rs-banners-container .owl-theme .owl-nav .owl-prev,
.rs-banners-container .owl-theme .owl-nav .owl-next {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.1);
  margin: auto;
  top: 0;
  bottom: 0;
  height: 45px;
  width: 38px;
  border-radius: 50%;
  color: transparent;
  cursor: pointer; }

.rs-banners-container .owl-theme .owl-nav .owl-prev:hover,
.rs-banners-container .owl-theme .owl-nav .owl-next:hover {
  /* background-color: #ebebeb; */
  background-color: rgba(255, 255, 255, 0.2);
  color: transparent; }

.rs-banners-container .owl-theme .owl-nav .owl-prev:before,
.rs-banners-container .owl-theme .owl-nav .owl-next:before {
  color: #fff;
  position: absolute;
  width: 100%;
  font-size: 2px;
  left: 0;
  text-align: center;
  line-height: 45px; }

.rs-banners-container .owl-theme .owl-nav .owl-prev {
  left: 10px; }

.rs-banners-container .owl-theme .owl-nav .owl-prev {
  /* background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIGlkPSJMYXllcl8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjUycHgiIGhlaWdodD0iNTJweCIgdmlld0JveD0iMCAwIDkyIDkyIiB4bWw6c3BhY2U9InByZXNlcnZlIiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCA5MiA5MiI+CjxwYXRoIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgZmlsbD0iIzY2NiIgaWQ9IlhNTElEXzQyM18iIGQ9Ik02MS44LDY4LjFjMS42LDEuNSwxLjYsNC4xLDAuMSw1LjdDNjEuMSw3NC42LDYwLDc1LDU5LDc1Yy0xLDAtMi0wLjQtMi44LTEuMWwtMjYtMjUgIEMyOS40LDQ4LjEsMjksNDcuMSwyOSw0NnMwLjQtMi4xLDEuMi0yLjlsMjYtMjVjMS42LTEuNSw0LjEtMS41LDUuNywwLjFjMS41LDEuNiwxLjUsNC4xLTAuMSw1LjdMMzguOCw0Nkw2MS44LDY4LjF6Ii8+Cjwvc3ZnPg==); */
  background-position: -3px;
  background-repeat: no-repeat;
  background-size: contain; }

.rs-banners-container .owl-theme .owl-nav .owl-next {
  right: 10px; }

.rs-banners-container .owl-theme .owl-nav .owl-next {
  /* background-image: url(data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJMYXllcl8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCIKCSB3aWR0aD0iOTJweCIgaGVpZ2h0PSI5MnB4IiB2aWV3Qm94PSIwIDAgOTIgOTIiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDkyIDkyIiBmaWxsPSIjNjY2IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHBhdGggaWQ9IlhNTElEXzQzOV8iIGQ9Ik02Myw0NmMwLDEuMS0wLjQsMi4xLTEuMiwyLjlsLTI2LDI1QzM1LDc0LjYsMzQsNzUsMzMsNzVjLTEuMSwwLTIuMS0wLjQtMi45LTEuMmMtMS41LTEuNi0xLjUtNC4xLDAuMS01LjcKCWwyMy0yMi4xbC0yMy0yMi4xYy0xLjYtMS41LTEuNi00LjEtMC4xLTUuN2MxLjUtMS42LDQuMS0xLjYsNS43LTAuMWwyNiwyNUM2Mi42LDQzLjksNjMsNDQuOSw2Myw0NnoiLz4KPC9zdmc+); */
  background-position: 3px;
  background-repeat: no-repeat;
  background-size: contain; }

.rs-banners-container:hover .owl-theme .owl-nav .owl-prev,
.rs-banners-container:hover .owl-theme .owl-nav .owl-next {
  background-color: rgba(255, 255, 255, 0.2); }

.rs-banners-container .owl-theme .owl-nav .owl-prev:hover,
.rs-banners-container .owl-theme .owl-nav .owl-next:hover {
  background-color: rgba(255, 255, 255, 0.6); }

@media (min-width: 768px) {
  .rs-banners-container .owl-theme .owl-dots {
    position: absolute;
    bottom: 10px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%); } }

@media (min-width: 992px) {
  .rs-banners-container .owl-theme .owl-dots {
    bottom: 20px; } }

@media (min-width: 1200px) {
  .rs-banners-container .owl-dots {
    opacity: 1; } }

.rs-banners-container {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.rs-banners-container * {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.rs-banners-container .owl-carousel {
  display: block;
  width: 100%; }

.rs-banners-container.__wide {
  width: 100%; }

.rs-banners-container.__underlay .owl-theme .owl-nav {
  top: 158px; }

.rs-banners-container.__center {
  width: 100%;
  margin-left: auto;
  margin-right: auto; }

.rs-banners-container.__leftbanner,
.rs-banners-container.__rightbanner {
  width: 100%; }

.rs-banners-container.__leftbanner .owl-theme .owl-nav {
  top: 0; }

.rs-banners-container.__leftbanner .owl-theme .owl-nav,
.rs-banners-container.__leftbanner .rs-banners .rs-banners_wrap,
.rs-banners-container.__rightbanner .owl-theme .owl-nav,
.rs-banners-container.__rightbanner .rs-banners .rs-banners_wrap {
  width: 100%; }

.rs-banners-container .rs-banners-sidebanner {
  position: absolute;
  width: 250px;
  overflow: hidden; }

.rs-banners-container .rs-banners-sidebanner.__left {
  left: 0; }

.rs-banners-container .rs-banners-sidebanner.__right {
  right: 0; }

.rs-banners-container .rs-banners.owl-theme .owl-nav .owl-prev,
.rs-banners-container .rs-banners.owl-theme .owl-nav .owl-next {
  height: 60px;
  width: 60px;
  -webkit-transition: all, 0.2s, ease-in-out 0s;
  -moz-transition: all, 0.2s, ease-in-out 0s;
  transition: all, 0.2s, ease-in-out 0s; }

.rs-banners-container .rs-banners.owl-theme .owl-dots {
  -webkit-transition: all, 0.2s, ease-in-out 0s;
  -moz-transition: all, 0.2s, ease-in-out 0s;
  transition: all, 0.2s, ease-in-out 0s; }

.rs-banners-container .rs-banners .rs-banners_product img {
  width: auto;
  position: absolute;
  bottom: 0;
  left: 0; }

.rs-banners-container .rs-banners.owl-theme.owl-carousel .owl-video-play-icon {
  background: url("owl.video.play.png") no-repeat; }

.rs-banners-container .rs-banners.owl-theme.owl-carousel .owl-video-tn {
  background-size: cover; }

.rs-banners-container.__wide .rs-banners_wrap,
.rs-banners-container.__wide .owl-theme .owl-nav {
  max-width: 85.38rem; }

.rs-banners-sidebanner.__left,
.rs-banners-sidebanner.__right {
  display: none; }

.rs_banner-preloader {
  position: absolute;
  top: auto;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 4; }

@media (min-width: 1200px) {
  .__leftbanner.__center .rs-banners {
    padding-left: 16.25rem; }
  .__leftbanner.__center .rs-banners .owl-nav {
    left: 16.25rem;
    width: auto; }
  .rs-banners-sidebanner.__left.js-sidebanners_selected {
    display: block; } }

@media (min-width: 992px) {
  .__rightbanner.__center .rs-banners {
    padding-right: 16.25rem; }
  .__rightbanner.__center .rs-banners .owl-nav {
    right: 16.25rem;
    width: auto; }
  .rs-banners-sidebanner.__right.js-sidebanners_selected {
    display: block; } }

@media (max-width: 800px) {
  .rs-banners-container .owl-theme .owl-nav {
    top: 0; }
  .rs-banners-container .owl-theme .owl-nav {
    display: none; } }

.l-head .rs-banners_infowrap, .landing-main .rs-banners_infowrap {
  width: 100%;
  text-align: center;
  margin-left: 0;
  position: relative;
  max-width: 38rem;
  margin: auto; }

.l-head .rs-banners_banner .rs-banners_product, .landing-main .rs-banners_banner .rs-banners_product {
  width: 100%;
  display: none; }

.l-head .rs-banners_banner .rs-banners_product img, .landing-main .rs-banners_banner .rs-banners_product img {
  max-width: none; }

.l-head .rs-banners .rs-banners_banner, .landing-main .rs-banners .rs-banners_banner,
.l-head .rs_banner-preloader,
.landing-main .rs_banner-preloader {
  height: 240px !important; }

.l-head .rs-banners_infowrap .rs-banners_info .rs-banners_title, .landing-main .rs-banners_infowrap .rs-banners_info .rs-banners_title {
  font-size: 18px;
  line-height: 22px;
  font-weight: 600; }

.l-head .rs-banners_infowrap .rs-banners_info .rs-banners_desc, .landing-main .rs-banners_infowrap .rs-banners_info .rs-banners_desc {
  line-height: 18px;
  font-size: 12px; }

@media (min-width: 576px) {
  .l-head .rs-banners_infowrap .rs-banners_info .rs-banners_title, .landing-main .rs-banners_infowrap .rs-banners_info .rs-banners_title {
    font-size: 27px;
    line-height: 33px;
    font-weight: 600; }
  .l-head .rs-banners_infowrap .rs-banners_info .rs-banners_desc, .landing-main .rs-banners_infowrap .rs-banners_info .rs-banners_desc {
    line-height: 26px;
    font-size: 16px; } }

@media (max-width: 767.98px) {
  .l-head.has-banner, .has-banner.landing-main {
    margin-bottom: 2rem; }
  .l-head__banner {
    margin-top: -2rem; } }

@media (min-width: 768px) {
  .l-head .rs-banners_infowrap .rs-banners_info .rs-banners_title, .landing-main .rs-banners_infowrap .rs-banners_info .rs-banners_title {
    font-size: 36px;
    line-height: 40px;
    font-weight: 700; }
  .l-head .rs-banners_infowrap .rs-banners_info .rs-banners_desc, .landing-main .rs-banners_infowrap .rs-banners_info .rs-banners_desc {
    line-height: 26px;
    font-size: 16px; }
  .l-head .rs-banners_infowrap, .landing-main .rs-banners_infowrap {
    height: 320px;
    bottom: 40px;
    top: auto;
    position: absolute;
    max-width: 100%; }
  
  .l-head .rs_banner-preloader,
  .landing-main .rs_banner-preloader,
  .l-head:not(.is-underlay) .l-head__banner,
  .landing-main:not(.is-underlay) .l-head__banner,
  .l-head:not(.is-underlay) .rs-banners .rs-banners_banner,
  .landing-main:not(.is-underlay) .rs-banners .rs-banners_banner {
    height: 400px !important; } }

@media (min-width: 992px) {
  .l-head .rs-banners_infowrap, .landing-main .rs-banners_infowrap {
    padding-left: 6.25rem;
    height: 440px;
    bottom: 60px;
    width: 55%;
    text-align: left; }
  .l-head .rs-banners_banner .rs-banners_product, .landing-main .rs-banners_banner .rs-banners_product {
    display: block;
    width: 50%; }
  
  .l-head .rs_banner-preloader,
  .landing-main .rs_banner-preloader,
  .l-head:not(.is-underlay) .l-head__banner,
  .landing-main:not(.is-underlay) .l-head__banner,
  .l-head:not(.is-underlay) .rs-banners .rs-banners_banner,
  .landing-main:not(.is-underlay) .rs-banners .rs-banners_banner {
    height: 500px !important; } }

@media (min-width: 1200px) {
  .l-head .rs-banners_infowrap, .landing-main .rs-banners_infowrap {
    width: 50%; } }

.rs-banners .u-carousel-indicators-v1,
.rs-banners .u-carousel-indicators-v1--white-inverse,
.rs-banners .u-carousel-indicators-v1--white {
  display: flex !important;
  width: 100%;
  flex-wrap: wrap;
  padding: 0 15px;
  justify-content: center; }
  @media (max-width: 767.98px) {
    .rs-banners .u-carousel-indicators-v1,
    .rs-banners .u-carousel-indicators-v1--white-inverse,
    .rs-banners .u-carousel-indicators-v1--white {
      display: none !important; } }

.rs-banners .u-carousel-indicators-v1 li,
.rs-banners .u-carousel-indicators-v1--white-inverse li,
.rs-banners .u-carousel-indicators-v1--white li {
  margin: .25rem; }

.rs-banners .u-carousel-indicators-v1 li.slick-active span,
.rs-banners .u-carousel-indicators-v1--white-inverse li.slick-active span,
.rs-banners .u-carousel-indicators-v1--white li.slick-active span {
  background-color: var(--primary);
  opacity: 1;
  width: 1.5rem; }

.rs-banners .u-carousel-indicators-v1 span,
.rs-banners .u-carousel-indicators-v1--white-inverse span,
.rs-banners .u-carousel-indicators-v1--white span {
  width: .4375rem;
  height: .4375rem;
  border-radius: .1875rem;
  background: #bdbdbd;
  opacity: 1;
  transition: width .25s,background .25s; }

.rs-banners .u-carousel-indicators-v1--white li.slick-active span {
  background-color: #fff; }

.rs-banners .u-carousel-indicators-v1--white li span {
  opacity: .5; }

.rs-banners .u-carousel-indicators-v1--white-inverse li.slick-active span {
  background-color: #fff;
  opacity: .3; }

.rs-banners .u-carousel-indicators-v1--white-inverse li span {
  opacity: .9; }

.rs-banners__nav {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.1);
  margin: auto;
  height: 60px !important;
  width: 60px !important;
  border-radius: 50%;
  color: rgba(125, 134, 152, 0.4) !important;
  cursor: pointer;
  font-size: 1.5rem !important;
  transition: all, 0.2s, ease-in-out 0s; }

.rs-banners-container:hover .rs-banners__nav {
  background-color: rgba(255, 255, 255, 0.2); }
  .rs-banners-container:hover .rs-banners__nav:hover {
    background-color: rgba(255, 255, 255, 0.6);
    color: var(--primary) !important; }

@media (min-width: 1200px) {
  .rs-banners__nav--prev {
    left: calc((100vw - 1366px) / 2 + 10px); } }

.rs-banners__nav--prev:before {
  mask-image: url(/b24app/app/assets/images/icons.svg#svg-arrow-left-view); }

@media (min-width: 1200px) {
  .rs-banners__nav--next {
    right: calc((100vw - 1366px) / 2 + 10px); } }

.rs-banners__nav--next:before {
  mask-image: url(/b24app/app/assets/images/icons.svg#svg-arrow-right-view); }
