@import '../../../../local/templates/megamart_default/resources/sass/system';

@import '../../../../local/templates/megamart_default/resources/sass/snippets/svg-icon-background';
@import '../../../../local/templates/megamart_default/components/bitrix/news.list/rs_banners/styles/redsign_banners';
@import '../../../../local/templates/megamart_default/components/bitrix/news.list/rs_banners/styles/theme';

// head__banner
.l-head {

	.rs-banners_infowrap {
		width: 100%;
		text-align: center;
		margin-left: 0;
		position: relative;
		max-width: 38rem;
		margin: auto;
	}

	.rs-banners_banner .rs-banners_product {
		width: 100%;
		display: none;
	}

	.rs-banners_banner .rs-banners_product img {
		max-width: none;
	}

	.rs-banners .rs-banners_banner,
	.rs_banner-preloader {
		height: 240px !important;
	}

	.rs-banners_infowrap .rs-banners_info .rs-banners_title {
		font-size: 18px;
		line-height: 22px;
		font-weight: 600;
	}

	.rs-banners_infowrap .rs-banners_info .rs-banners_desc {
		line-height: 18px;
		font-size: 12px;
	}

	@include media-breakpoint-up(sm) {
		.rs-banners_infowrap .rs-banners_info .rs-banners_title {
			font-size: 27px;
			line-height: 33px;
			font-weight: 600;
		}

		.rs-banners_infowrap .rs-banners_info .rs-banners_desc {
			line-height: 26px;
			font-size: 16px;
		}
	}
	@include media-breakpoint-down(sm) {

		&.has-banner {
			margin-bottom: map-get($spacers, 6);
		}

		&__banner {
			margin-top: -(map-get($spacers, 6));
		}
	}
	@include media-breakpoint-up(md) {
		.rs-banners_infowrap .rs-banners_info .rs-banners_title {
			font-size: 36px;
			line-height: 40px;
			font-weight: 700;
		}

		.rs-banners_infowrap .rs-banners_info .rs-banners_desc {
			line-height: 26px;
			font-size: 16px;
		}

		.rs-banners_infowrap {
			height: 320px;
			bottom: 40px;
			top: auto;
			position: absolute;
			max-width: 100%;
		}

		& .rs_banner-preloader,
		&:not(.is-underlay) &__banner,
		&:not(.is-underlay) .rs-banners .rs-banners_banner {
			height: 400px !important;
		}
	}
	@include media-breakpoint-up(lg) {
		.rs-banners_infowrap {
			padding-left: 6.25rem;
			height: 440px;
			bottom: 60px;
			width: 55%;
			text-align: left;
		}

		.rs-banners_banner .rs-banners_product {
			display: block;
			width: 50%;
		}

		& .rs_banner-preloader,
		&:not(.is-underlay) &__banner,
		&:not(.is-underlay) .rs-banners .rs-banners_banner {
			height: 500px !important;
		}

	}
	@include media-breakpoint-up(xl) {
		.rs-banners_infowrap {
			width: 50%;
		}
	}
}
// /head__banner

.landing-main {
	@extend .l-head;
}

.rs-banners {
	.u-carousel-indicators-v1,
	.u-carousel-indicators-v1--white-inverse,
	.u-carousel-indicators-v1--white {
		display: flex !important;
		width: 100%;
		flex-wrap: wrap;
		padding: 0 15px;
		justify-content: center;

		@include media-breakpoint-down(sm) {
			display: none !important;
		}
	}

	.u-carousel-indicators-v1 li,
	.u-carousel-indicators-v1--white-inverse li,
	.u-carousel-indicators-v1--white li {
		margin: .25rem;
	}

	.u-carousel-indicators-v1 li.slick-active span,
	.u-carousel-indicators-v1--white-inverse li.slick-active span,
	.u-carousel-indicators-v1--white li.slick-active span {
		background-color: var(--primary);
		opacity: 1;
		width: 1.5rem;
	}

	.u-carousel-indicators-v1 span,
	.u-carousel-indicators-v1--white-inverse span,
	.u-carousel-indicators-v1--white span {
		width: .4375rem;
		height: .4375rem;
		border-radius: .1875rem;
		background: #bdbdbd;
		opacity: 1;
		transition: width .25s,background .25s;
	}

	.u-carousel-indicators-v1--white li.slick-active span {
		background-color: #fff;
	}

	.u-carousel-indicators-v1--white li span {
		opacity: .5;
	}

	.u-carousel-indicators-v1--white-inverse li.slick-active span {
		background-color: #fff;
		opacity: .3;
	}

	.u-carousel-indicators-v1--white-inverse li span {
		opacity: .9;
	}

	&__nav {
		@extend %svg-icon-background;

		position: absolute;
		background-color: rgba(255, 255, 255, 0.1);
		margin: auto;
		height: 60px !important;
		width: 60px !important;
		border-radius: 50%;
		color: rgba(125, 134, 152, 0.4) !important;
		cursor: pointer;



		font-size: 1.5rem !important;
		transition: all, 0.2s, ease-in-out 0s;
	}

	&-container:hover &__nav {
		background-color: rgba(255, 255, 255, 0.2);

		&:hover {
			background-color: rgba(255, 255, 255, 0.6);
			color: var(--primary) !important;
		}
	}

	&__nav--prev {

		@each $breakpoint, $max-widths in $container-max-widths {
			@include media-breakpoint-up($breakpoint, $grid-breakpoints) {
				left: calc((100vw - #{$max-widths}) / 2 + 10px);
			}
		}

		&:before {
			mask-image: url(/b24app/app/assets/images/icons.svg#svg-arrow-left-view);
		}
	}

	&__nav--next {

		@each $breakpoint, $max-widths in $container-max-widths {
			@include media-breakpoint-up($breakpoint, $grid-breakpoints) {
				right: calc((100vw - #{$max-widths}) / 2 + 10px);
			}
		}

		&:before {
			mask-image: url(/b24app/app/assets/images/icons.svg#svg-arrow-right-view);
		}
	}
}