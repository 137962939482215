.owl-prev .rs-banner-nav,
 .owl-next svg.rs-banner-nav {
	fill: rgba(125, 134, 152, 0.4);
}

.rs-banners-container:hover .owl-prev .rs-banner-nav,
 .rs-banners-container:hover .owl-next .rs-banner-nav{
		fill: rgba(125, 134, 152, 0.6);

}




.owl-prev svg, .owl-next svg {
	font-size: 24px;

}
.rs-banners-container {
	width: 100%;
	position: relative;
}

.rs-banners {
	display: block;
	overflow: hidden;
}

.rs-banners_banner {
	opacity: 0;
	display: none;
	transition: .3s;
}

.rs-banners_banner:first-child {
	opacity: 1;
	display: block;
	position: relative;
	overflow: hidden;
}

.rs-banners_background {
	width: 100%;
	height: 100%;
	background-position: center bottom;
	background-size: cover;
}

.rs-banners_link {
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	z-index: 5;
	top: 0;
}

.rs-banners_wrap {
	width: 100%;
	position: absolute;
	top: 0;
	height: 100%;
	left: 0;
	right: 0;
	margin: auto;
}

.rs-banners_banner .rs-banners_product {
	position: absolute;
	width: 50%;
	top: 0;
	bottom: 0;
	right: 0;
	margin: auto;
	text-align: center;
	height: 100%;
}

.rs-banners_banner .rs-banners_product img {
	max-width: 100%;
	width: auto;
	max-height: 100%;
	display: inline-block;
}

.rs-banners_infowrap {
	height: 100%;
	width: 50%;
	display: table;
	margin-left: 15px;
	word-break: break-word;
}

.rs-banners_infowrap.rs-banners_infovideo {
	width: 100%;
	text-align: center;
}

.rs-banners_infowrap .rs-banners_info {
	display: table-cell;
	vertical-align: middle;
	word-break: break-word;
}

.rs-banners_infowrap .rs-banners_buttons {
	margin-top: 16px;
	position: relative;
	z-index: 99;
	margin-left: 16px;
}

.rs-banners_infowrap .rs-banners_buttons > .btn + .btn {
	margin-left: 8px;
}

.rs-banners_infowrap .rs-banners_button {
	margin-left: 15px;
	z-index: 9;
	position: relative;
}

.rs-banners_infowrap .rs-banners_info .rs-banners_title,
.rs-banners_infowrap .rs-banners_info .rs-banners_desc,
.rs-banners_infowrap .rs-banners_info .rs-banners_price {
	font-size: 18px;
	display: block;
	padding: 10px 15px;
	word-break: break-word;
}

.rs-banners_infowrap .rs-banners_info .rs-banners_title.rs-banners_video-blockwrap,
.rs-banners_infowrap .rs-banners_info .rs-banners_desc.rs-banners_video-blockwrap,
.rs-banners_infowrap .rs-banners_info .rs-banners_price.rs-banners_video-blockwrap {
	padding: 1vw 4vw;
	background: rgba(0, 0, 0, 0.5);
	color: #fff;
	margin-bottom: 20px;
}

.rs-banners_infowrap .rs-banners_info .rs-banners_desc {
	display: none;
}

.rs-banners.__product-reverse .rs-banners_banner .rs-banners_product {
	left: 0;
	right: auto;
}

.rs-banners.__product-reverse .rs-banners_banner .rs-banners_infowrap {
	right: 0;
	left: auto;
	position: absolute;
}

.rs-banners_bottom-line {
	display: none;
}

.rs-banners_video {
	position: absolute;
	width: 100%;
	height: 100%;
	background-color: #000;
}

.rs-banners_video video {
	height: 100%;
	width: 100%;
}

.rs-banners_video-play {
	position: absolute;
	width: 100%;
	height: 100%;
	cursor: pointer;
	z-index: 9;
}

.rs-banners-sidebanners {
    position: relative;
    z-index: 2;
}

@media (min-width: 992px) {
	.rs-banners_infowrap .rs-banners_info .rs-banners_desc {
		display: inline-block;
	}
}